:root {
  --primary: #3e64ff;
  --secondary: green;
  --black: #000000;
  --white: #ffffff;
  --danger: #f72585;
  --success: #4cc9f0;
}

body {
  overflow-x: hidden;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
